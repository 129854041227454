import React, { useRef } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavigation from "../common/SwiperNavigation";
import "swiper/css";
import { Navigation } from "swiper";
import "swiper/css/navigation";
import Link from "gatsby-link";

export const DesktopBestSellingProductCards = ({ products }) => {
  return (
    <div className="grid grid-cols-3 xl:gap-x-10 gap-x-4 gap-y-12">
      {products.map((product, index) => (
        <BestSellingProductCard product={product} key={index} />
      ))}
    </div>
  );
};

export const MobileBestSellingProductCards = ({ products }) => {
  const prevBtn = useRef<HTMLButtonElement>(null);
  const nextBtn = useRef<HTMLButtonElement>(null);
  return (
    <div className="[&_.swiper]:py-1 [&_.swiper]:px-1 relative">
      <Swiper
        breakpoints={breakpoints}
        modules={[Navigation]}
        speed={1000}
        navigation={{
          prevEl: prevBtn?.current && prevBtn.current,
          nextEl: nextBtn?.current && nextBtn.current,
          disabledClass: "hidden",
        }}
      >
        {products.map((product, index) => (
          <SwiperSlide key={index}>
            <BestSellingProductCard product={product} />
          </SwiperSlide>
        ))}
      </Swiper>
      <SwiperNavigation prevBtn={prevBtn} nextBtn={nextBtn} />
    </div>
  );
};

const breakpoints = {
  620: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
  490: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  250: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  0: {
    slidesPerView: 1.6,
    spaceBetween: 20,
  },
};

export const BestSellingProductCard = ({ product }) => {
  const productLogo = getImage(product?.logo?.localFile);
  return (
    <Link to={`/tp/${product.slug}/`}>
      <div className="flex flex-col md:flex-row md:items-center gap-x-3 w-[126px] md:w-auto hover:scale-95 hover:cursor-pointer transition-all duration-200 ease-in-out">
        {productLogo !== undefined && (
          <GatsbyImage
            image={productLogo}
            alt={product?.logo?.alternativeText}
            objectFit="cover"
            className="min-w-[126px] shadow-[0_2px_6px_0px_rgba(0,0,0,0.3)] mb-2"
          />
        )}
        <div className="lg:max-w-[166px]">
          <h3 className="mb-2 text-base md:text-[20px] leading-[21.09px] font-bold text-dsc-title">
            {product.name}
          </h3>
          <p className="text-dsc-body text-sm leading-4">
            {product?.Sections[0]?.subtitle}
          </p>
        </div>
      </div>
    </Link>
  );
};
