import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Link from "gatsby-link";
import CategoryBadge from "./CategoryBadge";

export const NewsCard = ({ news }) => {
  const featured_image = news.SEO.featured_image;
  const localFile = getImage(featured_image.localFile);

  return (
    <Link to={`/${news?.blog_categories[0]?.slug}/${news?.slug}/`}>
      <div className="border-b border-dsc-softgray">
        <div className="flex items-center gap-2 py-3 px-1 hover:scale-[0.97] hover:cursor-pointer transition-all duration-300 ease-in-out">
          {localFile != null ? (
            <GatsbyImage
              image={localFile}
              alt={news.SEO.featured_image.alternativeText}
              objectFit="cover"
              className="min-w-[180px]"
            />
          ) : featured_image?.url ? (
            <img
              src={`https://strapi-admin.digitalstacks.net${featured_image.url}`}
              alt={featured_image.alt}
              loading="lazy"
              width="180px"
              height="80px"
              className="mb-5"
            />
          ) : (
            <></>
          )}
          <div className="flex flex-col gap-y-1 md:gap-y-[6px]">
            <div className="flex items-center gap-x-2">
              <span className="font-bold text-xs leading-[14.06px] md:text-sm md:leading-4 text-dsc-body">
                {news?.release_date}
              </span>
              <CategoryBadge category={news?.blog_categories[0]?.title} />
            </div>
            <h3 className="text-sm md:text-lg leading-[16.41px] font-semibold md:leading-[21.09px]">
              {news?.title}
            </h3>
            <p className="text-xs leading-[14.06px] md:text-sm md:leading-[16.41px] text-dsc-body line-clamp-1">
              {news?.SEO?.meta_description}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export const UniqueNewsCard = ({ news }) => {
  const thumbnail = getImage(news?.SEO.featured_image.localFile);
  return (
    <Link to={`/${news?.blog_categories[0]?.slug}/${news?.slug}/`}>
      <div className="rounded-2xl flex items-center shadow-product-card mb-6">
        {thumbnail != null && (
          <GatsbyImage
            image={thumbnail}
            alt={news.SEO.featured_image.alternativeText}
            objectFit="contain"
            className="min-h-[130px] flex-1 overflow-hidden rounded-tl-2xl rounded-bl-2xl"
          />
        )}
        <div className="flex flex-col gap-y-2 md:gap-y-[6px] flex-1 px-2 py-4 ">
          <span className="font-bold text-xs leading-[14.06px] text-dsc-title">
            {news?.release_date}
          </span>
          <h3 className="md:dsc-h4 text-sm leading-[16.41px] font-semibold md:leading-[21.09px] text-dsc-title line-clamp-1">
            {news?.title}
          </h3>
          <p className="text-xs leading-[14.06px] md:text-sm md:leading-[16.41px] text-dsc-title line-clamp-2">
            {news?.SEO?.meta_description}
          </p>
          <div>
            <span className="font-bold bg-dsc-white-smoke text-dsc-title border border-dsc-softgray px-2 py-[2px] text-[9px] leading-[11.72px]">
              {news?.blog_categories[0]?.title}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};
