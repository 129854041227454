import React, { useRef } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import Link from "gatsby-link";
import "swiper/css";
import SwiperNavigation from "../common/SwiperNavigation";
import "swiper/css/navigation";
import { Navigation } from "swiper";

export const DesktopNewProductCards = ({ products }) => {
  return (
    <div className="grid grid-cols-3 xl:gap-x-10 gap-x-4 gap-y-12">
      {products.map((product, index) => (
        <NewProductCard key={index} product={product} />
      ))}
    </div>
  );
};

const breakpoints = {
  500: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  0: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
};

export const MobileNewProductCards = ({ products }) => {
  const prevBtn = useRef<HTMLButtonElement>(null);
  const nextBtn = useRef<HTMLButtonElement>(null);
  return (
    <div className="[&_.swiper]:py-1 [&_.swiper]:px-1 relative">
      <Swiper
        breakpoints={breakpoints}
        speed={1000}
        modules={[Navigation]}
        navigation={{
          prevEl: prevBtn?.current && prevBtn.current,
          nextEl: nextBtn?.current && nextBtn.current,
          disabledClass: "hidden",
        }}
      >
        <SwiperSlide>
          {products.slice(0, 3).map((product, index) => (
            <NewProductCard product={product} key={index} />
          ))}
        </SwiperSlide>
        {products.length > 3 && (
          <SwiperSlide>
            {products.slice(3, products.length).map((product, index) => (
              <NewProductCard product={product} key={index} />
            ))}
          </SwiperSlide>
        )}
      </Swiper>
      <SwiperNavigation prevBtn={prevBtn} nextBtn={nextBtn} />
    </div>
  );
};

export const NewProductCard = ({ product }) => {
  const productLogo = getImage(product?.logo?.localFile);

  const CardData = () => (
    <div className="flex items-center gap-x-3 hover:scale-95 hover:cursor-pointer transition-all duration-200 ease-in-out">
      {productLogo !== undefined && (
        <GatsbyImage
          image={productLogo}
          alt={product.logo.alternativeText}
          objectFit="cover"
          className="min-w-[126px] shadow-[0_2px_6px_0px_rgba(0,0,0,0.3)] mb-2"
        />
      )}
      <div className="lg:max-w-[166px]">
        <h3 className="mb-2 text-base lg:text-[20px] leading-[21.09px] font-bold text-dsc-title">
          {product.name}
        </h3>
        <p className="text-dsc-body text-sm leading-4">
          {product?.card_description || product?.Sections[0]?.subtitle}
        </p>
      </div>
    </div>
  );

  return product?.has_no_tp_page ? (
    <a href={product.link} target="_blank" rel="noreferrer">
      <CardData />
    </a>
  ) : (
    <Link to={`/tp/${product.slug}/`}>
      <CardData />
    </Link>
  );
};
