import React from "react";
import {
  DesktopBestSellingProductCards,
  MobileBestSellingProductCards,
} from "./BestSellingProductCards";
import useWindowSize from "../../hooks/useWindowSize";

const BestSellingProducts = ({
  isEmptyTitle = false,
  className = ``,
  products,
  breakpoint = 767,
}) => {
  const screen = useWindowSize();
  return (
    <div className={`${className}`}>
      {!isEmptyTitle && (
        <h2 className="dsc-h2 text-center mb-6 md:mb-12 leading-[21.09px] md:leading-[28px]">
          最も人気のある製品
        </h2>
      )}
      {screen.width !== undefined && screen.width > breakpoint ? (
        <DesktopBestSellingProductCards products={products} />
      ) : (
        <MobileBestSellingProductCards products={products} />
      )}
    </div>
  );
};

export default BestSellingProducts;
