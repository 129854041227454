import React from "react";
import Button from "../common/Button";

const Hero = () => {
  return (
    <div className="relative min-h-[456px] md:min-h-[450px] xl:min-h-[400px] w-full bg-[url('/images/homepage/mobile-bg.png')] md:bg-[url('/images/homepage/bg.png')] bg-contain md:bg-cover bg-no-repeat bg-center flex items-end md:items-center xl:px-20 px-4">
      <div className="flex justify-between items-end w-full">
        <div>
          <h1 className="text-dsc-title md:text-[32px] text-[28px] leading-[37.5px] font-bold mb-4 md:mb-[35px]">
            世界の最新
            <br />
            クラウドテクノロジーで
            <br />
            DXを支援
          </h1>
          <p className="md:hidden block font-semibold text-base leading-[18.75px] tracking-[0.03rem] mb-4">
            ビジネスのデジタル化に寄与する
            <br />
            クラウド製品の導入を支援
          </p>
          <Button
            className="inline-flex hover:bg-dsc-white hover:border hover:border-dsc-button hover:text-dsc-button"
            title="製品一覧"
            size="normal"
            path="/products/"
          />
        </div>
        <div className="hidden md:block">
          <p className="text-right leading-[21.09px] text-lg font-semibold tracking-[0.03rem]">
            ビジネスのデジタル化に寄与する
            <br />
            クラウド製品の導入を支援
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
