import React from "react";
import {
  DesktopNewProductCards,
  MobileNewProductCards,
} from "./NewProductCards";
import useWindowSize from "../../hooks/useWindowSize";

const NewProducts = ({ products }) => {
  const screen = useWindowSize();
  return (
    <div className="px-4 xl:px-[100px]">
      <h2 className="dsc-h2 text-center mb-6 md:mb-12 leading-[21.09px] md:leading-[28px]">
        最新の製品
      </h2>
      {screen.width !== undefined && screen.width > 767 ? (
        <DesktopNewProductCards products={products} />
      ) : (
        <MobileNewProductCards products={products} />
      )}
    </div>
  );
};

export default NewProducts;
