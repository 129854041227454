import React from "react";
import Divider from "../components/common/Divider";
import Layout from "../components/common/Layout";
import NewsSection from "../components/home/NewsSection";
import BestSellingProducts from "../components/home/BestSellingProducts";
import Customers from "../components/home/Customers";
import Hero from "../components/home/Hero";
import NewProducts from "../components/home/NewProducts";
import Meta from "../components/common/Meta";
import { graphql } from "gatsby";

const IndexPage = ({ data }) => {
  const meta = {
    title: "Digital Stacks｜世界の最新クラウドテクノロジーでDXを支援",
    description:
      "Digital Stacksは、DevOps、デジタルマーケティング、アナリティクス、ビジネスオペレーションなど、世界トップティアの最新クラウドテクノロジー各種製品を日本国内のお客さまに提供しています。導入コンサルティング、運用サポートなどのエキスパートサービスも含め、御社のDXを強力に支援いたします。",
    pathname: "/",
  };
  return (
    <Layout>
      <Meta meta={meta} />
      <Hero />
      <Divider spacing={72} />
      <NewProducts products={data?.newProducts?.nodes} />
      <Divider spacing={72} />
      <BestSellingProducts
        className="px-4 xl:px-[100px]"
        products={data?.bestSellingProducts?.nodes}
      />
      <Divider spacing={72} />
      <Customers />
      <Divider spacing={72} />
      <NewsSection newsData={data?.news.nodes} />
      <Divider spacing={72} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query MyQuery {
    bestSellingProducts: allStrapiProduct(
      filter: { status: { eq: "best_selling" } }
      sort: { order: ASC, fields: best_selling_product_homepage_order }
      limit: 6
    ) {
      nodes {
        name
        slug
        card_description
        Sections {
          ... on STRAPI__COMPONENT_SECTIONS_HERO_SECTION {
            subtitle
          }
        }
        logo {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 126
                quality: 100
                width: 126
                aspectRatio: 1
                transformOptions: { fit: COVER, cropFocus: CENTER }
                webpOptions: { quality: 100 }
                placeholder: TRACED_SVG
                layout: FIXED
              )
            }
          }
        }
      }
    }
    newProducts: allStrapiProduct(
      filter: { is_latest: { eq: true } }
      sort: { order: ASC, fields: latest_product_hompage_order }
      limit: 6
    ) {
      nodes {
        name
        slug
        card_description
        Sections {
          ... on STRAPI__COMPONENT_SECTIONS_HERO_SECTION {
            subtitle
          }
        }
        logo {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 126
                quality: 100
                width: 126
                aspectRatio: 1
                transformOptions: { fit: COVER, cropFocus: CENTER }
                webpOptions: { quality: 100 }
                placeholder: TRACED_SVG
                layout: FIXED
              )
            }
          }
        }
      }
    }
    news: allStrapiBlog(limit: 6, sort: { fields: release_date, order: DESC }) {
      nodes {
        SEO {
          meta_description
          featured_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 186
                  height: 80
                  transformOptions: { fit: COVER, cropFocus: ATTENTION }
                  webpOptions: { quality: 100 }
                  placeholder: TRACED_SVG
                  layout: FIXED
                )
              }
            }
            alternativeText
          }
        }
        slug
        release_date(formatString: "YYYY.MM.DD ddd")
        title
        blog_categories {
          title
          slug
        }
      }
    }
  }
`;
