import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const Customers = () => {
  return (
    <div className="px-4 xl:px-[100px]">
      <h2 className="dsc-h2 leading-[21.09px] md:leading-[28px] text-center mb-6 md:mb-12">
        私たちのお客様
      </h2>
      <div className="grid grid-cols-3 md:grid-cols-5 w-full gap-x-4 gap-y-4">
        <StaticImage
          src="../../../static/images/homepage/mixi.png"
          alt="Mixi"
          objectFit="cover"
          quality={100}
        />
        <StaticImage
          src="../../../static/images/homepage/capcom.png"
          alt="Capcom"
          objectFit="cover"
          quality={100}
        />
        <StaticImage
          src="../../../static/images/homepage/dena.png"
          alt="Dena"
          objectFit="cover"
          quality={100}
        />
        <StaticImage
          src="../../../static/images/homepage/fromsoftware.png"
          alt="From Software"
          objectFit="cover"
          quality={100}
        />
        <StaticImage
          src="../../../static/images/homepage/es.png"
          alt="Es"
          objectFit="cover"
          quality={100}
        />
        <div className="block md:hidden">
          <StaticImage
            src="../../../static/images/homepage/jstream.png"
            alt="Jstream"
            objectFit="cover"
            quality={100}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 md:grid-cols-4 w-full gap-x-4 max-w-[780px] mx-auto gap-y-4">
        <div className="hidden md:block">
          <StaticImage
            src="../../../static/images/homepage/jstream.png"
            alt="Jstream"
            objectFit="cover"
            quality={100}
          />
        </div>
        <StaticImage
          src="../../../static/images/homepage/docomo.png"
          alt="Docomo"
          objectFit="cover"
          quality={100}
        />
        <StaticImage
          src="../../../static/images/homepage/bellface.png"
          alt="Bellface"
          objectFit="cover"
          quality={100}
        />
        <StaticImage
          src="../../../static/images/homepage/fujitsu.png"
          alt="Fujitsu"
          objectFit="cover"
          quality={100}
        />
      </div>
    </div>
  );
};

export default Customers;
