import React from "react";
import useWindowSize from "../../hooks/useWindowSize";
import Button from "../common/Button";
import { NewsCard, UniqueNewsCard } from "../common/NewsCards";

const NewsSection = ({ newsData }) => {
  const screen = useWindowSize();
  const isMobile = screen.width && screen?.width <= 640;
  return (
    <div className="px-4 xl:px-[100px]">
      <h2 className="dsc-h2 text-center mb-6 md:mb-12 leading-[21.09px] md:leading-[28px]">
        お知らせ
      </h2>
      <div className="mb-6 md:mb-10">
        {isMobile ? (
          <>
            <UniqueNewsCard news={newsData[0]} />
            {newsData.slice(1, newsData.length).map((news, index) => (
              <NewsCard news={news} key={index} />
            ))}
          </>
        ) : (
          <>
            {newsData?.map((news, index) => {
              return index < 5 && <NewsCard news={news} key={index} />;
            })}
          </>
        )}
      </div>
      <div className="flex lg:gap-x-9 justify-center gap-x-6 [&_a]:block [&_a]:flex-none">
        <Button
          title="お知らせ一覧へ"
          path="/news/"
          size="normal"
          variant="normal"
          className="hover:bg-dsc-white hover:border hover:border-dsc-button hover:text-dsc-button"
        />
      </div>
    </div>
  );
};

export default NewsSection;
