import React from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

const SwiperNavigation = ({ prevBtn, nextBtn }) => {
  return (
    <div>
      <button
        ref={prevBtn}
        aria-label="previous button"
        className="absolute w-[36px] h-[36px] rounded-full bg-[#F0F0F0] top-[50%] left-0 my-auto p-2 flex justify-center items-center z-[2]"
      >
        <BsArrowLeft color="#2a2a2a" size={20} />
      </button>
      <button
        ref={nextBtn}
        aria-label="next button"
        className="absolute w-[36px] h-[36px] rounded-full bg-[#F0F0F0] top-[50%] right-0 my-auto p-2 flex justify-center items-center z-[2]"
      >
        <BsArrowRight color="#2a2a2a" size={20} />
      </button>
    </div>
  );
};

export default SwiperNavigation;
